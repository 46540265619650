
import { defineComponent, ref, onMounted } from "vue";
// import Datatable from "@/components/kt-datatable/Datatable.vue";
import Datatable from "@/components/kt-datatable/CredentialDataTabel.vue";

import { MenuComponent } from "@/assets/ts/components";
// import AttachManufacturerModal from "@/components/modals/forms/AttachManufacturerModal.vue";
// import EditManufacturerModal from "@/components/modals/forms/EditManufacturerModal.vue";
// import ChangeStatusModal from "@/components/modals/forms/ChangeStatusModal.vue";
// import FilterConfigurationManufacturer from "@/components/modals/forms/FilterConfigurationManufacturer.vue";
// import FilterCOnf from "@/components/modals/forms/FilterCredentials.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading, ElNotification } from "element-plus";
import router from "@/router";
interface ICustomer {
  id: number;
  name: string;
  panNo: string;
  industry: string;
  email: string;
  manufacturerStatus: string;
}

export default defineComponent({
  mixins: [Globals],
  name: "ConfigureManufacturer",
  components: {
    Datatable,
    // FilterConfigurationManufacturer
    // AddConfigureManufacturerModal,
    // AttachManufacturerModal,
    // EditManufacturerModal,
    // ChangeStatusModal,
  },

  data() {
    return {
      configuremanufacturerdata: [],
      render: false,
      dialogVisible: false,
      isActive: false,
      value1: new Date(),
      initCust: [] as ICustomer[],
      search: "",
      searchFlag:true,
      statusData: {
        application: "",
        organizationType: "",
        organizationId: "",
        consumerCode: "",
        data: {
          panNumber: "",
          inActiveDate: new Date(),
          active: true
        },
      },
    };
  },

  methods: {
    setCountPage(data) {
      const { count, page } = data;
      this.getConfigureManufacturer(page, count);
    },
    filterData(){
      return this.configuremanufacturerdata      
    },
    setFilter(data){
      console.log("set fitler in configure manufacturer..", data);
    },
    searchItems() {
      this.configuremanufacturerdata["organization_list"].splice(
        0,
        this.configuremanufacturerdata["organization_list"].length,
        ...this.initCust
      );

      if (this.search !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.configuremanufacturerdata["organization_list"].length; j++) {
          if (this.searchingFunc(this.configuremanufacturerdata["organization_list"][j], this.search)) {
            results.push(this.configuremanufacturerdata["organization_list"][j]);
          }

        }
        this.configuremanufacturerdata["organization_list"].splice(
          0,
          this.configuremanufacturerdata["organization_list"].length,
          ...results
        );
        if(this.configuremanufacturerdata['organization_list'].length==0 && this.searchFlag==true)
        {
          this.searchFlag=false
             ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if(this.configuremanufacturerdata['organization_list'].length!=0)
        {
          this.searchFlag=true
        }
        

        
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    displayData(data) {
      this.configuremanufacturerdata = JSON.parse(JSON.stringify(data));
      this.render = true;
      this.initCust.splice(
        0,
        this.configuremanufacturerdata["organization_list"].length,
        ...this.configuremanufacturerdata["organization_list"]
      );

    },
    getConfigureManufacturer(page_number=1, page_count=25) {
      this.render = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let url = "/organizations?organizationType=M&mappingFields=true" + "&pageNumber="+ page_number + "&pageCount=" + page_count 
      ApiService.get(url)
        .then((data) => {
          loading.close();
          this.displayData(data.data.data);
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    changeStatus(panNumber, manufacturerCode, active) {
      this.statusData.application = "";
      this.statusData.organizationType = "C";
      this.statusData.consumerCode = String(this.globalData.organizationId);
      this.statusData.organizationId = manufacturerCode;
      this.statusData.data.panNumber = panNumber;
      this.statusData.data.inActiveDate = null;
      this.statusData.data.active = active;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.put("/consumerHasManufacturer", this.statusData)
        .then((data) => {
          loading.close();
          this.getConfigureManufacturer();
          ElNotification({
            title: "Success",
            message: "Status updated sucessfully.",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          // loading.close();
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
    },
    editManufacturer(panNo, name, distributorCode) {
      (this.$refs["editManufacturerForm"] as any).callUpdate(
        panNo,
        name,
        distributorCode
      );
    },
    attachManufacturer() {
      (this.$refs["attachManufacturerForm"] as any).callAttach();
    },
    ChangeT(test) {
      this.getConfigureManufacturer();
    },
  },
  mounted() {
    this.getConfigureManufacturer();
  },
  setup() {
    const checkedManufacturers = ref([]);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      // },
      {
        name: "Manufacturer ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Manufacturer Name",
        key: "name",
        sortable: true,
      },
      {
        name: "PAN Number",
        key: "panNo",
        sortable: true,
      },
      {
        name: "Industry",
        key: "industry",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      
      // {
      //   name: "Distributor Code",
      //   key: "distributorCode",
      //   sortable: true,
      // },
      {
        name: "STATUS",
        key: "status",
        sortable: true,
      },
      // {
      //   name: "Action",
      //   key: "actions",
      // },
    ]);
    

    onMounted(() => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Configure Manufacturers", ["Manage"]);
    });

    

    

    
    return {
      tableHeader,
      checkedManufacturers,
    };
  },
});
